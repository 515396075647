export default {
    namespaced: true,
    state: {
        userInfo: {
            id: 'abc123',
            name: 'Nam Nguyen',
            email: 'namnguyen@gmail.com',
            password: '123'
        }
    }
}