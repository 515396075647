<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:foo="http://www.example.com/fooML" >
    <circle cx='50' cy='25' r='20' foo:drag='true'/>
  </svg>
</template>

<script>
export default {
  name: "BaseIcon"
}
</script>

<style scoped>

</style>